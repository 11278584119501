<template>
    <main>
        <section class="flex">
            <div class="text">
                <h2 data-animation="paragraph">Want more information about us or what we do?</h2>
            </div>
            <div class="btn">
                <a href="mailto:info@openrelay.xyz">
                    <button>Send a message</button>
                </a>
            </div>
        </section>
    </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
main {
    padding: 60px 0px;
    background: #2901c2;
    section {
        .text {
            width: 50%;
            h2 {
                font-size: 2em;
                color: white;
            }
        }
        .btn {
            button {
                padding: 17px 28px;
                font-size: 1em;
                color: white;
                border: 1px solid white;
                background: transparent;
                border-radius: 9px;
            }
        }
    }
}
@media (max-width: 765px) {
    main {
        section {
            flex-direction: column;
            .text {
                width: 80%;
            }
            .btn {
                margin-top: 40px;
                width: 80%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 765px) {
    main {
        section {
            .text {
                width: 100%;
            }
            .btn {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
</style>
