<template>
    <main id="Products">
        <section>
            <div class="begin">
                <h2 data-animation="heading">Products</h2>
                <p data-animation="paragraph">
                    Our current products serve as an example of what we do best. Our talent and skills are dedicated to developing even better
                    technology.
                </p>
            </div>

            <div class="prod_wrap">
                <div v-for="product in products" :key="product.id" class="flex contain">
                    <div class="copy">
                        <h2 data-animation="heading">
                            {{ product.name }}
                        </h2>
                        <p data-animation="paragraph">{{ product.info }}</p>
                        <div class="btn">
                            <a :href="product.url">
                                <button>Learn more</button>
                            </a>
                        </div>
                    </div>
                    <div class="img">
                        <img
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-easing="ease-out"
                            loading="lazy"
                            data-aos-mirror="false"
                            data-aos-once="true"
                            :src="require(`@/assets/${product.img}`)"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import product_data from "../data/data.json";
export default {
    data() {
        return {
            products: product_data.products.reverse(),
        };
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 100px 0px;
    overflow-x: hidden;
    section {
        .prod_wrap {
            .contain {
                overflow-x: hidden;
                margin-top: 80px;
                &:nth-child(2) {
                    flex-direction: row-reverse;
                }
                .copy {
                    width: 45%;
                    h2 {
                        font-size: 1.9em;
                    }
                    p {
                        margin: 15px 0px;
                    }
                    .btn {
                        margin: 30px 0px;
                        button {
                            padding: 10px 14px;
                            border: 2px solid #bfb3ed;
                            border-radius: 9px;
                            background: white;
                            color: #2901c2;
                            font-family: inherit;
                            font-weight: normal;
                            font-size: 1em;
                        }
                    }
                }
                .img {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .letter {
            margin-top: 80px;
            p {
                font-family: 1em;
                color: #2901c2;
            }
        }
    }
}
@media (max-width: 865px) {
    main {
        padding: 60px 0px;
        section {
            .prod_wrap {
                width: 80%;
                margin: 0 auto;
                .contain {
                    margin-top: 60px;
                    flex-direction: column;
                    gap: 1rem;
                    &:nth-child(2) {
                        flex-direction: column;
                    }
                    .copy {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: 500px) {
    main {
        section {
            .prod_wrap {
                width: 100%;
            }
        }
    }
}
</style>
