<template>
    <div class="div1 wrap__"></div>
    <div class="div2 wrap__"></div>
    <div class="wrap__ menu_">
        <nav>
            <ul>
                <li>
                    <a href="#Mission">Mission</a>
                </li>
                <li>
                    <a href="#Products">Products</a>
                </li>
                <li>
                    <a href="#Timeline">Timeline</a>
                </li>
                <li>
                    <a href="https://blog.openrelay.xyz/">Blog</a>
                </li>
                <li>
                    <a href="#Team">Team</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { gsap, Circ } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        let tl = gsap.from(".wrap__", {
            xPercent: 100,
            duration: 0.7,
            stagger: 0.3,
            ease: Circ.easeOut,
        });

        let bl = gsap.from(".menu_ nav ul li", {
            delay: 1.2,
            opacity: 0,
            rotateX: -90,
            stagger: 0.25,
        });
        document.querySelectorAll(".menu_ nav ul li").forEach((item) => {
            item.addEventListener("click", () => {
                document.querySelector(".menu .buttons").classList.toggle("open");
                bl.reverse();
                tl.reverse();

                setTimeout(() => {
                    this.$emit("closeMenu");
                }, 2000);
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.wrap__ {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 30;
    top: 0px;
}
.div1 {
    background: #ff66d8;
}
.div2 {
    background: #2b02c3;
}
.menu_ {
    background: #2b02c3;
    background: #7abae0;
    nav {
        width: 100%;
        display: flex;
        height: 100%;
        // justify-content: center;
        flex-direction: column;
        margin-top: 45%;
        align-items: center;
        ul {
            display: flex;
            flex-direction: column;
            gap: 2rem 0px;
            li {
                font-family: Monument;
                font-size: 2em;
                color: white;
            }
        }
    }
}
</style>
