<template>
    <main>
        <section id="Mission">
            <div class="begin">
                <h2 data-animation="heading">Uncompromising Web3 infrastructure</h2>
                <p data-animation="paragraph">We're building an awesome decentralized internet that will be controlled by people like you.</p>
            </div>
            <div class="dipole flex">
                <div class="img">
                    <svg width="370" height="370" viewBox="0 0 370 370" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Group 16">
                            <g id="Group 2">
                                <rect id="Rectangle 43" width="370" height="370" transform="matrix(1 0 0 -1 0 370)" fill="#2901C2" />
                            </g>
                            <g id="Group">
                                <rect id="Rectangle 54" width="259" height="111" transform="matrix(1 0 0 -1 111 111)" fill="#92DCE5" />
                            </g>

                            <g id="Group">
                                <rect id="Rectangle 55" width="166.5" height="111" transform="matrix(1 0 0 -1 203.5 222)" fill="#92DCE5" />
                            </g>
                            <g id="Group">
                                <rect id="Rectangle 56" width="92.5" height="111" transform="matrix(1 0 0 -1 277.5 333)" fill="#92DCE5" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="copy">
                    <div class="each">
                        <h3>mission</h3>
                        <p>To build the foundation of the decentralized web for the 21st century and beyond</p>
                    </div>
                    <div class="each">
                        <h3>vision</h3>
                        <p>
                            OpenRelay's vision is to liberate tomorrow's innovators to think creatively, act fearlessly - and boldly shape the future
                            of human activity.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
    mounted() {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from("#Group", {
            opacity: 0,
            duration: 0.5,
            delay: 1,
            stagger: 0.5,
            scrollTrigger: {
                trigger: "#Mission",
                toggleActions: "play play reverse reverse",
                ease: Power3.easeOut,
                start: "top center",
                end: "bottom 30%",
            },
        });

        gsap.from(".copy .each", {
            opacity: 0,
            xPercent: 23,
            stagger: 0.3,
            duration: 1,
            scrollTrigger: {
                trigger: "#Mission",
                toggleActions: "play play reverse reverse",
                ease: Power3.easeOut,
                start: "top center",
                end: "bottom 30%",
            },
        });
    },
};
</script>

<style lang="scss" scoped>
main {
    padding: 80px 0px;
    section {
        .dipole {
            margin-top: 70px;
            .img {
                svg {
                    width: 100%;
                }
            }
            .copy {
                width: 35%;
                .each {
                    &:nth-child(2) {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
}
@media (max-width: 765px) {
    main {
        overflow-x: hidden;
        padding: 60px 0px;
        section {
            overflow-x: hidden;
            .img {
                width: 80%;
            }
            .dipole {
                overflow-x: hidden;
                flex-direction: column;
                .copy {
                    width: 70%;
                    margin-top: 20%;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    main {
        padding: 1rem 0px;
        section {
            overflow-x: hidden;
            .img {
                width: 100%;
            }
            .dipole {
                .copy {
                    width: 100%;
                }
            }
        }
    }
}
</style>
