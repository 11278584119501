import Splitting from "splitting";
import gsap from "gsap";

export const split = () => {
    const p = document.querySelectorAll("[data-animation='paragraph']");
    const H = document.querySelectorAll("[data-animation='header']");
    const h = document.querySelectorAll("[data-animation='heading']");
    h.forEach((item) => {
        Splitting({
            target: item,
            by: "chars",
        });
    });
    H.forEach((item) => {
        Splitting({
            target: item,
            by: "words",
        });
    });
    p.forEach((item) => {
        const line = Splitting({
            target: item,
            by: "lines",
        });
        // line.forEach((splitResult) => {
        //     const wrappedLines = splitResult.lines
        //         .map(
        //             (wordsArr) => `
        //     <span class="line">
        //       ${wordsArr
        //           .map(
        //               (word) => `${word.outerHTML}<span class="whitespace">
        //      </span>`
        //           )
        //           .join("")}
        //     </span>`
        //         )
        //         .join("");
        //     splitResult.el.innerHTML = wrappedLines;
        // });
        line.forEach((splitResult) => {
            const wrappedLines = splitResult.words
                .map(
                    (wordsArr) => `
                    <span class="word_wrap">
                          ${wordsArr.outerHTML}
                     </span>`
                )
                .join("");
            splitResult.el.innerHTML = wrappedLines;
        });
    });
};

export const animate = () => {
    const paragraph = document.querySelectorAll("[data-animation='paragraph']");
    paragraph.forEach((item) => {
        gsap.set(item.querySelectorAll(".word"), {
            yPercent: 100,
            opacity: 0.1,
            rotateX: 50,
            transformStyle: "preserve-3d",
        });
        IO(item, { threshold: 1 }).then(() => {
            const elem = item.querySelectorAll(".word");
            gsap.to(elem, {
                yPercent: 0,
                opacity: 1,
                rotateX: 0,
                stagger: elem.length > 100 ? 0.01 : 0.02,
                duration: elem.length > 100 ? 0.5 : 0.6,
                ease: "easeOut",
            });
        });
    });

    const header = document.querySelectorAll("[data-animation='header']");
    header.forEach((item) => {
        gsap.set(item.querySelectorAll(".word"), {
            opacity: 0,
            rotateX: 80,
            transformStyle: "preserve-3d",
        });
        IO(item, { threshold: 1 }).then(() => {
            const elem = item.querySelectorAll(".word");
            gsap.to(elem, {
                opacity: 1,
                rotateX: 0,
                stagger: 0.08,
                duration: 0.8,
                ease: "easeOut",
            });
        });
    });
    const h = document.querySelectorAll("[data-animation='heading']");
    h.forEach((item) => {
        gsap.set(item.querySelectorAll(".char"), {
            opacity: 0,
            yPercent: 100,
            transformStyle: "preserve-3d",
        });
        IO(item, {
            threshold: 1,
            rootMargin: "-30px",
        }).then(() => {
            const elem = item.querySelectorAll(".char");
            gsap.to(elem, {
                opacity: 1,
                yPercent: 0,
                stagger: elem.length > 100 ? 0.01 : 0.02,
                duration: elem.length > 100 ? 0.5 : 0.6,
                ease: "easeOut",
            });
        });
    });
};

export const IO = (item, options) => {
    return new Promise((resolve) => {
        const observer = new window.IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    resolve();
                }
            });
        }, options);
        observer.observe(item);
    });
};
