<template>
    <main>
        <section class="flex">
            <div class="logo">
                <a href="/">
                    <img :src="require(`@/assets/${smallLogos.logo}`)" alt="" />
                </a>
            </div>
            <nav>
                <ul class="flex">
                    <li>
                        <a href="#Mission">Mission</a>
                    </li>
                    <li>
                        <a href="#Products">Products</a>
                    </li>
                    <li>
                        <a href="#Timeline">Timeline</a>
                    </li>
                    <li>
                        <a href="https://blog.openrelay.xyz/">Blog</a>
                    </li>
                    <li>
                        <a @click="passFunct" href="#Team">Team</a>
                    </li>
                </ul>
            </nav>
            <div @click="openMenu" class="menu">
                <div class="buttons">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import images from "../exports/media";
export default {
    name: "heading",
    data() {
        return {
            smallLogos: images.smallLogos,
        };
    },
    methods: {
        openMenu() {
            document.querySelector(".menu .buttons").classList.toggle("open");
            this.$emit("openMenu");
        },
        passFunct() {
            this.$emit("passFunction");
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    top: 0;
    padding-top: 20px;
    z-index: 70;
    background: white;
    section {
        @media (max-width: 480px) {
            width: 90%;
        }
        nav {
            width: 40%;
            @media (max-width: 1100px) {
                width: 60%;
            }
            @media (max-width: 865px) {
                display: none;
            }
            ul {
                justify-content: space-between;
                li {
                    font-family: inherit;
                    font-size: 0.99em;

                    a {
                        color: #383757;
                    }
                }
            }
        }
        .menu {
            display: none;
            z-index: 90;
            .buttons {
                span {
                    width: 2rem;
                    background: rgb(0, 0, 0);
                    height: 0.13rem;
                    margin: 8px 0px;
                    transition: 0.6s ease-in-out;
                    display: block;
                }
            }
            .buttons.open {
                span {
                    background: white;
                    &:nth-child(1) {
                        transform: rotateZ(35deg) translateY(8px);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotateZ(-35deg) translateY(-13px);
                    }
                }
            }
            @media (max-width: 865px) {
                display: block;
            }
        }
    }
}
</style>
