import { createApp } from "vue";
import App from "./App.vue";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { split, animate } from "./exports/utils";

window.addEventListener("DOMContentLoaded", () => {
    split();
    animate();
    AOS.init();
});

createApp(App).mount("#app");
